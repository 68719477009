import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { AutoFormattedMessage } from '@pcid/string-utils';
import track from '@pcid/analytics/track';

import Button from '../../button';
import InputGroup from '../input-group';

/**
* inputPassword is a reusable component that can be used for password entry input fields. It
* provides a show/hide password option that user can use to toggle between a masked/unmasked
* password.
*
* @prop {object} field - the name of the form field passed from the form in the parent component
* @prop {string} className - Extra classnames for the element
*/

export default class extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		allowToggle: PropTypes.bool,
		disabled: PropTypes.bool,
		fieldName: PropTypes.string,
	}

	static defaultProps = {
		allowToggle: false,
	}

	state = {
		hidden: true,
	}

	toggleHidden = () => {
		this.setState((prevState) => {
			track.customLink({ linkName: `${prevState.hidden ? 'show' : 'hide'}-password` });
			return { hidden: !prevState.hidden };
		});
	}

	render() {
		const { className, allowToggle, disabled, fieldName, ...rest } = this.props;
		let { hidden } = this.state;
		if (disabled) hidden = true;

		const toggleButtonMessage = hidden ? 'show' : 'hide';
		return (
			<div className={classnames(className, 'password-group', disabled && 'password-group--disabled')}>
				<InputGroup
					maxLength={64}
					{...rest}
					type={hidden ? 'password' : 'text'}
					disabled={disabled}
					autoCapitalize="off"
				/>
				{ allowToggle && (
					<Button
						theme="link-grey"
						className="password-group__toggle-button"
						onClick={this.toggleHidden}
						ariaLabel={{
							id: `common.${toggleButtonMessage}Password.aria`,
							values: { fieldName },
						}}
						disabled={disabled}
					>
						<AutoFormattedMessage id={`common.${toggleButtonMessage}Password`} />
					</Button>
				) }
			</div>
		);
	}
}
