import React, { useEffect } from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { withFeatureFlags, getLoginMessage } from '@pcid/components/config-context';
import withTarget from '@pcid/components/with-target';
import Layout, { SecondaryCTA } from '@pcid/components/layout';
import Link from '@pcid/link';
import { AutoFormattedMessage } from '@pcid/string-utils';
import { localeCodes } from '@pcid/locale-utils';
import { withLocale } from '@pcid/components/locale-provider';
import { handleHousekeeping, getInitialStatus } from '@pcid/utils/form-utils';
import { getEmailStorage, getRememberedUserStorage } from '@pcid/storage-utils';
import Sprite from '@pcid/sprite';

import LoginForm from '../components/forms/loginForm';
import { processLogin } from '../actions/login';

const Login = ({
	locale = localeCodes.EN,
	loginCtx,
	featureFlags: { createAccountURL, forgotPasswordURL, sso, checkbox, maintenance, mandatory2fa },
	target,
}) => {
	useEffect(() => {
		target.applyOffers();
	}, []);

	const { ssoError } = Router.query;

	if (maintenance) {
		return Router.replace('/maintenance');
	}

	const convergenceBanner = (
		<div className="login-form__convergence-banner">
			<div className="login-form__convergence-banner-logo-container">
				<Sprite
					group="banners"
					symbol={`pc-id_${locale}`}
					alt="PCid Logo"
					className="layout__pcid-logo-banner"
				/>
			</div>
			<AutoFormattedMessage
				id="form.createAccount.description1"
			/>
			<hr className="login-form__divider" />
			<Sprite
				group="banners"
				symbol={`banner-optimum_${locale}`}
				className="login-form__convergence-banner-svg"
			/>
			<Sprite
				group="banners"
				symbol={`banner-express_${locale}`}
				className="login-form__convergence-banner-svg"
			/>
			<Sprite
				group="banners"
				symbol={`banner-health_${locale}`}
				className="login-form__convergence-banner-svg"
			/>
			<span className="login-form__last-two-svg-group">
				<Sprite
					group="banners"
					symbol={`banner-shoppers_${locale}`}
					className="login-form__convergence-banner-svg"
				/>
				<Sprite
					group="banners"
					symbol="banner-joe-fresh"
					className="login-form__convergence-banner-svg login-form__last-svg"
				/>
			</span>
		</div>
	);

	return (
		<Layout
			title="page.title.signIn"
			className="login-page"
			convergenceBanner={convergenceBanner}
			convergenceBannerPosition="top"
		>
			<Formik
				render={(props) => (
					<LoginForm
						{...props}
						setEmail={(email) => { getEmailStorage().set(email); }}
						forgotPasswordURL={forgotPasswordURL}
						checkbox={checkbox}
						ssoError={ssoError}
					/>
				)}
				onSubmit={handleHousekeeping((data) => processLogin({ ...data, sso, mandatory2fa }))}
				initialValues={{
					email: getEmailStorage().get() || '',
					password: '',
					loginCtx,
					rememberMe: getRememberedUserStorage().get() !== null,
				}}
				initialStatus={getInitialStatus(getLoginMessage())}
			/>
			{ createAccountURL && (
				<SecondaryCTA>
					<Link to={createAccountURL}>
						<AutoFormattedMessage id="form.createAccount" />
					</Link>
				</SecondaryCTA>
			) }
		</Layout>
	);
};

Login.propTypes = {
	locale: PropTypes.string,
	loginCtx: PropTypes.string.isRequired,
	featureFlags: PropTypes.object.isRequired,
	target: PropTypes.object.isRequired,
};


export default withFeatureFlags(withLocale(withTarget(Login)));
