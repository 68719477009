import React from 'react';

import LoadingPage from '../loading-page';

export default function withTarget(Component) {
	return class TargetProvider extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				targetLoaded: false,
				targetResponse: null,
			};
		}

		async componentDidMount() {
			if (!window) {
				return this.setState({ targetLoaded: true });
			}

			let response = {};

			try {
				if (window.adobe) {
					response = await window.adobe.target.getOffers({
						request: {
							execute: {
								mboxes: [],
								pageLoad: {
									parameters: {},
								},
							},
							prefetch: { views: [{}] },
						},
					});
				}
			} finally {
				// Even if the request fails, we still want it to count as "loaded" so
				// the page doesn't appear to hang. Calling `applyOffers()` with a blank
				// response object will just fail silently.
				this.setState({ targetResponse: response, targetLoaded: true });
			}

			return response;
		}

		applyOffers = async () => {
			const { targetResponse } = this.state;
			if (window.adobe) {
				await window.adobe.target.applyOffers({	response: targetResponse });
			}
		};

		render() {
			const { targetLoaded } = this.state;
			if (!targetLoaded) return <LoadingPage />;

			const targetProps = {
				loaded: targetLoaded,
				applyOffers: this.applyOffers,
			};

			return <Component target={targetProps} {...this.props} />;
		}
	};
}
