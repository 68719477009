import React from 'react';
import PropTypes from 'prop-types';

import {
	decodeString,
	getLoginCtxStorage,
	getRelyingPartyStorage,
	getEmailStorage,
	getRememberedUserStorage,
} from '@pcid/storage-utils';
import oops from '@pcid/utils/oops';
import LoadingPage from '@pcid/components/loading-page';
import { DUMMY_RP } from '@pcid/utils/relying-party-utils/';
import Login from '../../containers/login';
import { trackLoginPageView } from '../../actions/track-analytics';

export default class extends React.Component {
	static propTypes = {
		intent: PropTypes.string,
	}

	constructor(props) {
		super(props);

		const { intent } = props;
		const relyingParty = getRelyingPartyStorage().get();
		const encodedEmail = getRememberedUserStorage().get();
		this.loginCtx = getLoginCtxStorage().getIn(relyingParty);
		this.intentToRegister = intent === 'register';
		if (!getEmailStorage().get()) {
			getEmailStorage().set(encodedEmail ? decodeString(encodedEmail) : '');
		}

		if (!relyingParty || relyingParty === DUMMY_RP) {
			oops.noLoginInfo();
		} else if (!this.loginCtx) {
			oops.expired();
		}
	}

	componentDidMount() {
		trackLoginPageView();
	}

	render() {
		return this.loginCtx && !this.intentToRegister
			? <Login loginCtx={this.loginCtx} />
			: <LoadingPage />;
	}
}
