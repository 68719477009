import React from 'react';
import PropTypes from 'prop-types';

import InputGroup from '@pcid/components/forms/input-group';
import InputPassword from '@pcid/components/forms/input-password';
import SubmitButton from '@pcid/components/forms/submit-button';
import Form from '@pcid/components/forms/form';
import Link from '@pcid/link';
import { AutoFormattedMessage } from '@pcid/string-utils';
import { getRememberedUserStorage, decodeString } from '@pcid/storage-utils';
import speck, {
	toExist,
	toBeAnEmail,
	errorCodes,
} from '@pcid/utils/validation';
import FormMessage from '@pcid/components/forms/form-message';

export const formId = 'login';

const validateEmail = (email) => speck(
	email,
	toExist.else(errorCodes.emailRequired),
	toBeAnEmail
);

const LoginForm = ({
	setEmail,
	forgotPasswordURL,
	checkbox,
	setFieldValue,
	values,
	ssoError,
}) => (
	<Form
		id={formId}
		className="login-form"
		heading="form.common.heading"
		breadcrumb
		showIcon={false}
	>
		{ ssoError && (
			<FormMessage
				status="warning"
				message="form.login.ssoLoginError"
			/>
		) }
		<InputGroup
			name="email"
			type="email"
			className="login-form__email"
			label="common.email"
			autoComplete="username"
			onChange={({ target: { value } }) => {
				setFieldValue('rememberMe', false);
				setEmail(validateEmail(value) ? '' : value);
				if (getRememberedUserStorage().get()
					&& value === decodeString(getRememberedUserStorage().get())
				) setFieldValue('rememberMe', true);
			}}
			validate={validateEmail}
			required
		/>
		<InputPassword
			name="password"
			className="login-form__password"
			label="common.password"
			autoComplete="current-password"
			validate={(password) => speck(
				password,
				toExist.else(errorCodes.passwordRequired)
			)}
			allowToggle
			required
		/>
		{ forgotPasswordURL && (
			<div className="login-form__forgot-password-link">
				<Link to={forgotPasswordURL}>
					<AutoFormattedMessage id="form.common.forgotPassword" />
				</Link>
			</div>
		) }
		{ checkbox && (
			<InputGroup
				name="rememberMe"
				type="checkbox"
				label="common.rememberMe"
				ariaLabel="common.rememberMeAria"
			/>
		) }
		<SubmitButton
			messageId="form.common.signIn"
			analyticsId="login-screen"
			analyticsSubmitPayload={{ rememberMe: values.rememberMe }}
		/>
	</Form>
);

LoginForm.propTypes = {
	setEmail: PropTypes.func.isRequired,
	forgotPasswordURL: PropTypes.string,
	setFieldValue: PropTypes.func,
	values: PropTypes.object,
	checkbox: PropTypes.bool,
	ssoError: PropTypes.bool,
};

export default LoginForm;
